<template>
  <div class="sky_wrap">
    <div class="sky_btn">
      <div
        class="sky_item"
        v-for="(item, index) in skyList"
        :key="index"
        :class="activeIndex === index ? 'active' : ''"
        @click="changeIndex(index)"
      >
        {{ item.tit }}
      </div>
    </div>
    <div class="sky_content">
      <div class="content_left">
        <div class="left_tit">{{ skyTitle }}</div>
        <div class="left_icon_wrap">
          <div
            class="icon_item"
            v-for="(item, index) in skyImgList"
            :key="index"
            ref="iconItem"
          >
            <i :class="item.src"></i>
            <span v-html="item.txt"></span>
          </div>
        </div>
        <!-- <div class="left_btn hidden-xs-only" @click="handleToDeomo">获取产品演示</div> -->
      </div>
      <div class="content_right">
        <img :src="skycurrentImg" alt="" />
        <!-- <div class="left_btn hidden-sm-and-up" @click="handleToDeomo">获取产品演示</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      skyTitle:
        '地理区位配套，特色要素展示，资源空间划分。全方位，立体呈现项目优势',
      skyImgList: [
        {
          src: '',
          txt: '<span style="font-weight:bold;padding-right:10px;">招商项目</span>后台配置发布，移动端自动展示',
        },
        {
          src: '',
          txt: '<span style="font-weight:bold;padding-right:10px;">潜客画像</span>所有访问人员，自动收录，生成画像',
        },
        {
          src: '',
          txt: '<span style="font-weight:bold;padding-right:10px;">VR实景</span>地理配套，空间布局，价位参考一应俱全',
        },
      ],
      skycurrentImg:require('../../assets/img/33342e3d61.png')
    }
  },
  props: {
    skyList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    changeIndex(index) {
      this.activeIndex = index
      switch (this.activeIndex) {
        case 1:
          this.skyTitle =
            '可视可编辑的平面房源分布，自定义房源空间拆合，一图总览租赁，适时调整招商策略'
          this.skyImgList = [
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">实时的租赁统计</span>基所有物业资源的租赁状态，租赁面积，情况分析',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">持续的执行获取</span>资源空置率，租赁到期时间，房源签约客户......',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">强大的空间展示</span>自定义划分租赁空间，实时输出招商信息',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">有效的线索对接</span>线上线下结合，避免资源错招，重招，漏招',
            },
          ]
          this.skycurrentImg = require('../../assets/img/222.a7a2174.png')
          break
        case 2:
          this.skyTitle =
            '对访问用户进行活跃度，游览量，关键操作步骤分析，展示从注册到当前使用状况的完整历程'
          this.skyImgList = [
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">潜客画像</span>便捷获取跟踪潜在客户的动态与信息，调整招商策略',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">预约看房</span>线上提交看房申请，及时获取客户详情',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">大数据招商</span>按需对接，打通全国全量企业数据库，多维度细化挖掘潜客',
            },
          ]
          this.skycurrentImg = require('../../assets/img/111.855fbdc (1).png')
          break
        default:
          this.skyTitle =
            '地理区位配套，特色要素展示，资源空间划分。全方位，立体呈现项目优势'
          this.$refs.iconItem.map(v=>{
            v.style.width = '90%'
              })
              this.skyImgList = [
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">招商项目</span>后台配置发布，移动端自动展示',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">潜客画像</span>所有访问人员，自动收录，生成画像',
            },
            {
              src: '',
              txt: '<span style="font-weight:bold;padding-right:10px;">VR实景</span>地理配套，空间布局，价位参考一应俱全',
            },
          ],
          this.skycurrentImg = require('../../assets/img/33342e3d61.png')
          break
      }
    },
    handleToDeomo(){
      this.$router.push('/demo')
    }
  },
}
</script>

<style lang="scss" scoped>
.sky_wrap {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.02) inset;
  @media screen and (max-width: 768px) {
    width: 95%;
    background: #f7f7f7;
  }
  .sky_btn {
    width: 100%;
    display: flex;
    .sky_item {
      flex: 1;
      height: 80px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      position: relative;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        height: 60px;
        text-align: center;
        font-size: 13px;
      }
    }
    .active {
      color: #fff;
      background: #2091d3;
      font-weight: bold;
      &::before {
        content: '▲';
        position: absolute;
        color: #2091d3;
        font-size: 40px;
        transform: rotateX(180deg);
        bottom: -27px;
      }
    }
  }
  .sky_content {
    width: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .content_left {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .left_tit {
        color: #2d3937;
        font-size: 17px;
        min-height: 90px;
        line-height: 20PX;
        @media screen and (max-width: 768px) {
          font-size: 0.9rem;
          padding: 10px 20px;
          color: #646464;
        }
      }
      .left_icon_wrap {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
        .icon_item {
          width: 90%;
          height: 50px;
          @media screen and (max-width: 768px) {
            height: 50px;
          }
          i {
            color: #7bbde3;
            @media screen and (max-width: 768px) {
              font-size: 20px;
            } 
          }
          span {
            color: #646464;
             @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }
      .left_btn {
        width: 150PX;
        height: 40PX;
        background: linear-gradient(61deg, #ffb826, #fff01a);;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
      }
    }
    .content_right {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
      text-align: right;
      img {
        margin-top: 30px;
        width: 80%;
      }
      .left_btn {
        width: 180px;
        height: 50px;
        background: linear-gradient(61deg, #ffb826, #fff01a);;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 120px;
          height: 35px;
          font-size: 14px;
          border-radius: 20px;
          margin: 20px auto;
          cursor: pointer;
        }
      }
    }
  }
}
</style>